@media (max-width: 1100px)
  .header
    align-items: flex-start
    .buttons
      flex-direction: column-reverse
      align-items: flex-end!important
      .connect__value
        margin-top: 10px!important
        margin-bottom: 20px!important
      .connect__value:hover
        text-decoration: underline
      & > div .button
        margin: 5px

    &__main
      display: grid
      grid-gap: 10px
      margin-right: 3rem

@media (max-width: 1023px)
  .header
    height: auto
    padding-top: var(--offset)
    &__sidebar-open
      display: block

@media (max-width: 767px)
  .header
    position: static
    &._scroll
      pointer-events: auto
      .header__main
        opacity: 1
    &__main
      display: grid
      grid-gap: 15px
    &__menu
      display: grid
      grid-gap: 10px
      justify-items: flex-start
    &__menu-item
      font-size: 10px !important
      border-bottom-width: 0px
    &__sidebar-open
      display: block
