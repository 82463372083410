\:root
  --color-text: #3C3D41
  --red: #E17285
  --color1: #008CB9
  --color2: #009ACC
  --color3: #00AEE5
  --color4: #23BFF1
  --color5: #47D2FF
  --color6: #F0F3F4
  --color7: rgba(142, 143, 145, 0.2)
  --color8: #F6F9FE
  --color9: rgba(0, 0, 0, 0.2)
  --color10: #747474
  --color11: #212121
  --color12: #101010
  --color13: rgba(255, 255, 255, 0.4)
  --color14: rgba(142, 143, 145, 0.4)
  --offset: 4rem
// var(--color)

