.referral-table
  margin-top: 30px
  &__title
    font-weight: 400
    font-size: 40px
    line-height: 60px
    letter-spacing: -0.04em
    color: #FFFFFF
    margin: 2rem 0

.referal-table
  td
    white-space: pre-wrap
  &__first_block
    display: flex
    span
      width: 17rem
      overflow-wrap: break-word
      word-wrap: break-word
      hyphens: auto


@import "media"
