.footer
  padding: 12rem 0 4rem 0
  &__contacts
    padding-bottom: 1em
    &-header
      margin-bottom: 1em
    &-item
      margin-bottom: 0.3em
    &-link
      text-decoration: underline
  &__bottom
    +flex(row nowrap, space-between, flex-end)
  &__menu-item
    letter-spacing: -0.02em
    margin-right: 2.5rem
    transition: all 0.3s
    display: inline-block
    text-decoration: underline
    &:hover
      text-decoration-color: transparent
  &__form
    max-width: 60rem
    margin-bottom: 11.7rem
    display: grid
    grid-gap: 1.9rem

@import "media"
