//@font-face
//  src: url('../fonts/helveticaultralight.ttf') format('truetype')
//  font-family: "Helvetica"
//  font-weight: 100
//  font-display: swap
//@font-face
//  src: url('../fonts/helveticaultralightitalic.ttf') format('truetype')
//  font-family: "Helvetica"
//  font-style: italic
//  font-weight: 100
//  font-display: swap
//@font-face
//  src: url('../fonts/helveticathin.ttf') format('truetype')
//  font-family: "Helvetica"
//  font-weight: 200
//  font-display: swap
//@font-face
//  src: url('../fonts/helveticathinitalic.ttf') format('truetype')
//  font-family: "Helvetica"
//  font-style: italic
//  font-weight: 200
//  font-display: swap
//@font-face
//  src: url('../fonts/helveticalight.ttf') format('truetype')
//  font-family: "Helvetica"
//  font-weight: 300
//  font-display: swap
//@font-face
//  src: url('../fonts/helveticalightitalic.ttf') format('truetype')
//  font-family: "Helvetica"
//  font-style: italic
//  font-weight: 300
//  font-display: swap
//@font-face
//  src: url('../fonts/helveticaregular.ttf') format('truetype')
//  font-family: "Helvetica"
//  font-weight: 400
//  font-display: swap
//@font-face
//  src: url('../fonts/helveticaitalic.ttf') format('truetype')
//  font-family: "Helvetica"
//  font-style: italic
//  font-weight: 400
//  font-display: swap
//@font-face
//  src: url('../fonts/helveticamedium.ttf') format('truetype')
//  font-family: "Helvetica"
//  font-weight: 500
//  font-display: swap
//@font-face
//  src: url('../fonts/helveticamediumitalic.ttf') format('truetype')
//  font-family: "Helvetica"
//  font-style: italic
//  font-weight: 500
//  font-display: swap
//@font-face
//  src: url('../fonts/helveticabold.ttf') format('truetype')
//  font-family: "Helvetica"
//  font-weight: 600
//  font-display: swap
//@font-face
//  src: url('../fonts/helveticabolditalic.ttf') format('truetype')
//  font-family: "Helvetica"
//  font-style: italic
//  font-weight: 600
//  font-display: swap
//@font-face
//  src: url('../fonts/helveticaheavy.ttf') format('truetype')
//  font-family: "Helvetica"
//  font-weight: 800
//  font-display: swap
//@font-face
//  src: url('../fonts/helveticaheavyitalic.ttf') format('truetype')
//  font-family: "Helvetica"
//  font-style: italic
//  font-weight: 800
//  font-display: swap
//@font-face
//  src: url('../fonts/helveticablack.ttf') format('truetype')
//  font-family: "Helvetica"
//  font-weight: 900
//  font-display: swap
//@font-face
//  src: url('../fonts/helveticablackitalic.ttf') format('truetype')
//  font-family: "Helvetica"
//  font-style: italic
//  font-weight: 900
//  font-display: swap

@font-face
  font-family: "icomoon"
  src: url("../../fonts/icomoon/icomoon.ttf") format("truetype"), url("../../fonts/icomoon/icomoon.svg") format("svg"), url('../../fonts/icomoon/icomoon.eot') format('embedded-opentype'), url('../../fonts/icomoon/icomoon.woff') format('woff')
  font-weight: normal
  font-style: normal
  font-display: swap
