@media (max-width: 1023px)
  .index
    margin: 0
    & + .footer
      width: 100%
      margin-left: 0
      padding-left: 0
      padding-right: 0
      background: none

@media (max-width: 767px)
  .index
    margin: 0
    .section
      &:nth-child(4)
        .section__header
          padding: 0
