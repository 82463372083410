.brands
  +grid2(28rem, 1fr, 0, 0)
  border-top: 1px solid var(--color13)
  border-left: 1px solid var(--color13)
  &__item
    +flex(column nowrap, center, center)
    height: 20rem
    border-right: 1px solid var(--color13)
    border-bottom: 1px solid var(--color13)
    padding: 5rem

@import "media"
