@media (max-width: 767px)
  .title
    font-size: 3.6rem
    &.__warning
      font-size: 7.0rem
      margin-top: 105px

  .subtitle
    font-size: 2.4rem
    &.__warning
      font-size: 1.8rem

  .title-main
    font-size: 7rem
