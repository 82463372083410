@media (max-width: 1200px)
  .information
    &__item
      &:last-child
        max-width: initial
@media (max-width: 767px)
  .information
    display: grid
    grid-gap: 5rem
    &__item
      margin: 0
      padding: 0
      border: none
    &__title
      margin-left: 0
