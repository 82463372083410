@media (max-width: 1023px)
  .sidebar-menu
    position: fixed
    left: -10rem
    transition: all 0.3s
    &__close
      display: block
      position: absolute
      right: 2rem
      top: calc(100% + 2rem)
      pointer-events: none
      opacity: 0
      transition: all 0.3s
      i.icon
        font-size: 2.5rem
    &__icon
      display: none
    &__activation
      &:checked
        & + .sidebar-menu
          left: 0
          box-shadow: 0px 3px 15px 1px black
          .sidebar-menu__close
            opacity: 1
            pointer-events: auto
    &__open
      display: block
      margin-right: 15px
    &__logo-img
      width: 100%
      height: 100%
    &__item
      min-width: initial
      &:last-child
        min-width: initial

@media (max-width: 1023px)
  .sidebar-menu
    &__item
      padding: 10px 4rem
