@import "../utility/utility"
.common
  font-size: 10px
  scroll-behavior: smooth
  overflow-anchor: none
  *
    box-sizing: border-box
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0)
    -webkit-tap-highlight-color: transparent
    outline: none
    text-align: left
  &__body
    font-family: "Helvetica", sans-serif
    margin: 0
    font-weight: 400
    font-size: 16px
    color: white
    position: relative
    background-color: black
img, iframe
  display: block
  max-width: 100%
img
  object-fit: contain
.app
  display: flex
  flex-flow: column nowrap
  min-height: 100vh
  position: relative
  width: calc(100% - 10rem)
  background: url("../../img/bg.jpg") repeat top left
  background-size: 100% auto
  margin-left: 10rem
  //overflow-x: hidden
  z-index: 1
  label, button
    cursor: pointer
  input, button, select, textarea
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    outline: none
    border: none
    background: none
    font-family: inherit
    font-size: 16px
    box-shadow: none
    border-radius: 0
  input::-webkit-input-placeholder, select::-webkit-input-placeholder, textarea::-webkit-input-placeholder
    +font(16px, 400, white)
  input:-moz-placeholder, select:-moz-placeholder, textarea:-moz-placeholder
    +font(16px, 400, white)
  input::-moz-placeholder, select::-moz-placeholder, textarea::-moz-placeholder
    +font(16px, 400, white)
  input:-ms-input-placeholder, select:-ms-input-placeholder, textarea:-ms-input-placeholder
    +font(16px, 400, white)
  textarea
    overflow: auto
  select, textarea
    &::-ms-expand
      display: none
  input[type='number']
    -moz-appearance: textfield
  input[type='submit']
    border: none
  input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
    -webkit-appearance: none
    display: none
  a
    text-decoration: none
    color: inherit
  .anchor
    top: -10px
    position: absolute
  .main
    width: 100%
    flex-grow: 1
    padding: 0 var(--offset)
    max-width: 1600px
  .hidden
    display: none
  .link-full
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 10
    cursor: pointer
  .section
    position: relative
    padding-top: 2rem
    &._with-aside
      display: grid
      grid-template-columns: 35.5rem 1fr
    &._mb
      margin-bottom: 9rem
    &._bg
      background: var(--color12)
      padding: 6rem var(--offset) 5rem var(--offset)
    &._full
      width: calc(100% + var(--offset) * 2)
      margin-left: calc(0px - var(--offset))
    &__aside
      padding-top: 3.2rem
      border-top: 1px solid var(--color13)
      padding-right: 15px
      .section__header
        padding-left: 8px
    &__hint
      opacity: 0.6
      font-size: 12.5px
      margin-top: 3.5rem
      letter-spacing: 0.005em
    &__breadcrumbs
      margin-bottom: 7rem
      margin-top: 4rem
    &__header
      margin-bottom: 2.5rem
    &__subtitle
      font-weight: 600
      color: var(--color10)
      margin-top: 10px
      letter-spacing: -0.02em
  .separator
    border-bottom: 1px solid
    margin: 4rem 0
    opacity: 0.4
  .inner
    width: 100%
    max-width: 1000px
  .flex-grow
    flex-grow: 1

  @import "media"

@media (max-width: 1520px)
  .common
    font-size: 9px
@media (max-width: 1360px)
  .common
    font-size: 8px
@media (max-width: 1220px)
  .common
    font-size: 7px
@media (max-width: 1023px)
  .app
    width: 100%
    margin-left: 0
  \:root
    --offset: 3rem
