.input
  height: 7rem
  padding: 0 2rem
  width: 100%
  font-size: 18px
  text-overflow: ellipsis
  letter-spacing: -0.015em
  &._light
    color: white
    border-bottom: 2px solid
    padding: 0 0 1.8rem 0
    height: auto

.input__range
  margin-top: 20px
  appearance: auto
  background: #fff
  width: 100%
@import "media"
