.step
  font-size: 1.8rem
  border-bottom: 1px solid var(--color13)
  padding-bottom: 4.1rem
  padding-right: 2.5rem
  &:last-child
    padding-bottom: 0
    border-bottom: none
  &__number
    font-size: 6rem
    color: var(--color1)
    margin-bottom: 3.5rem
    line-height: 0.6
  &__row
    +flex(row nowra, flex-start, flex-start)
    font-weight: 300
  &__title
    margin-right: 5.5rem
    width: 30rem
    min-width: 30rem
  &__description
    margin-top: -5px

.steps
  display: grid
  grid-gap: 4.2rem
  &._centered
    margin: auto
    max-width: 71rem

@import "media"
