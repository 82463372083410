.breadcrumbs
  +flex(row wrap, flex-start, baseline)
  font-size: 2rem
  &__item
    transition: all 0.3s
    margin-bottom: 5px
    span
      border-bottom: 1px solid transparent
    &:hover
      color: var(--color5)

    &:last-child
      div
        display: none
    &.active
      font-weight: 600

      span
        border-color: var(--color1)

