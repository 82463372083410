.information
  +flex(row nowrap, flex-start, flex-start)
  line-height: 1.4
  &__item
    display: grid
    grid-gap: 14px
    padding-right: 4rem
    margin-left: 6.7rem
    border-right: 1px solid var(--color13)
    flex-basis: 51%
    &:last-child
      border-right: none
      padding-right: 0
      max-width: 35rem
    &:first-child
      margin-left: 0
      .information__text
        max-width: 50rem
        margin-top: 8px
        margin-bottom: -8px
  &__title
    font-size: 2rem
    font-weight: 500
    letter-spacing: .01em
    margin-left: -3px
  &__text
    font-size: 16px
    line-height: 1.5
    //letter-spacing: 0.01em
    letter-spacing: -0.02em
    margin-left: -4px

@import "media"
