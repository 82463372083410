.info-general
  +flex(column nowrap, flex-start, stretch)
  background: white
  box-shadow: 0px 11px 20px rgba(171, 171, 171, 0.24)
  padding: 2rem 2rem 3.5rem 2.5rem
  border-radius: 10px
  overflow: hidden
  min-height: 22.5rem
  color: var(--color-text)
  &__header
    +flex(row nowrap, space-between, flex-start)
    margin-bottom: 2rem
  &__title
    font-weight: 600
    font-size: 2.2rem
  &__content
    flex-grow: 1
