@media (max-width: 1023px)
  .balance
    height: auto
    //padding: 5rem 0 3rem 0

@media (max-width: 767px)
  .balance
    &__amount
      white-space: nowrap
      font-size: 7rem
  .validation
    font-size: 12px
    margin-bottom: -10px
    margin-top: 10px
    &__separator
      margin: 0px 6px
