.index
  //margin-top: -2rem
  & + .footer
    padding-top: 5.9rem
    width: calc(100% + 8rem)
    margin-left: -4rem
    padding-left: 4rem
    padding-right: 4rem
    background: url("../../img/bg-2.jpg") top center
    background-size: cover
  .section
    &:nth-child(1)
      padding-top: 1px
    &:nth-child(3)
      padding-top: 1px
      .title
        letter-spacing: -0.045em
    &:nth-child(4)
      padding-top: 3.4rem
      .section__header
        padding: 0 0 0 5px
        margin-bottom: -1px
    &:nth-child(5)
      padding-top: 2.1rem
    &:nth-child(6)
      padding-top: 6.7rem
    &__header
      margin-bottom: 5px


@import "media"
