.form
  background: white
  color: black
  border-radius: 10px
  max-width: 90rem
  &__header
    padding: 5rem 5rem 3rem 5rem
    border-bottom: 2px solid var(--color6)
  &__content
    padding: 3.5rem 6.5rem 5rem 5rem
    display: grid
    grid-gap: 6rem
  &__label
    margin-bottom: 15px
    font-size: 2.4rem
  &__button
    margin-top: 10px

@import "media"
