.app .button._disabled {
  border: 1px solid;
  background: var(--color10);
  cursor: default;
}

.app .button._disabled:hover {
  -webkit-filter: none;
  filter: none;
}

.highcharts-credits {
  display: none;
}

.highcharts-scrollbar {
  display: none;
}

.app .table td.error {
  text-align: right;
  vertical-align: middle;
  color: #ee1b1b;
  font-weight: bold;
  font-size: 16px;
}

.app .table td.redirect {
  text-align: left;
  vertical-align: middle;
  font-weight: bold;
  font-size: 16px;
}
.app .table td.redirect .redirect {
  color: #008cb9;
}

.app .table td.redirect .redirect_error {
  color: #ee1b1b;
}

.app .table td.onchain {
  text-align: right;
  vertical-align: middle;
  padding: 15px;
}
