.balance
  +flex(row nowrap, flex-end, flex-end)
  height: 18rem
  &__amount
    white-space: nowrap
    font-size: 14rem
    letter-spacing: -0.04em
    text-align: right
    line-height: 1

.validation
  display: flex
  justify-content: flex-end
  font-size: 14px
  letter-spacing: -0.02em
  margin-bottom: -30px
  margin-top: 5px
  &__separator
    width: 1px
    height: 16px
    margin: 0px 12px
    background-color: white
    opacity: .8
  &__button
    font-weight: lighter
  &__caption
    font-weight: bolder
    padding-right: 12px
  &__item
    transition: all 0.3s
    margin-bottom: 5px
  a
    &:hover
      color: var(--color5)

@import "media"
