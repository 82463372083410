.info-main
  +flex(column nowrap, flex-start, stretch)
  background: white
  box-shadow: 0px 11px 20px rgba(171, 171, 171, 0.24)
  border-radius: 10px
  overflow: hidden
  min-height: 22.5rem
  color: var(--color-text)
  &__header
    +flex(row nowrap, space-between, center)
    +font(2.2rem, 600, white)
    background: var(--color1)
    padding: 2rem 3rem
    i.icon
      font-size: 3rem
      color: var(--color6)
  &__value
    +flex(column nowrap, center, center)
    font-size: 5.5rem
    font-weight: 600
    padding: 2rem
    flex-grow: 1
    text-align: center

@import "media"
