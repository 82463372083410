.button
  +flex(row nowrap, center, center)
  font-weight: 500
  font-size: 14px
  text-transform: uppercase
  display: inline-flex
  text-align: center
  padding: 15px 1.8rem
  min-height: 6rem
  background: var(--color1)
  min-width: 16.7rem
  transition: all 0.3s
  cursor: pointer
  color: white
  border: 1px solid var(--color1)
  &:hover
    filter: brightness(1.2)
  &._light
    border: 1px solid rgba(255, 255, 255, 0.63)
    background: none
    &:hover
      border-color: var(--color1)
  &._medium
    border: 1px solid var(--color1)
    color: var(--color1)
    background: none     
  &._middle
    min-width: 28rem
    font-size: 12px
    border: 2px solid white
  &._full
    width: 100%
  &._large
    min-height: 7rem
    font-weight: 600
    font-size: 2.4rem

.buttons
  +flex(row wrap, flex-start, flex-start)
  margin: -10px
  & > div > *
    margin: 10px
  &  .desktop_only.button
    margin: 10px

.buttons_container
  display: flex
  justify-content: space-between
  width: 100%
  .button
    flex: 1 1 0px
  .button:not(:last-child)
    margin-right: 20px

@import "media"
