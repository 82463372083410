@media (max-width: 767px)
  .footer
    padding-top: 6rem
    &__bottom
      display: grid
      grid-gap: 3rem
      justify-content: initial
    &__menu
      grid-row: 2 / 3
    &__form
      margin-bottom: 5rem
