.icon
  display: inline-flex

[class^="icon-"], [class*=" icon-"]
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important
  speak: none
  font-style: normal
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: 1

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

.icon-plus:before
  content: "\e90b"

.icon-menu2:before
  content: "\e901"

.icon-radar:before
  content: "\e904"

.icon-menu:before
  content: "\e907"

.icon-message:before
  content: "\e906"

.icon-arrows:before
  content: "\e903"

.icon-search:before
  content: "\e902"

.icon-eye:before
  content: "\e900"

.icon-down:before
  content: "\e905"

.icon-chart:before
  content: "\e90e"

.icon-star:before
  content: "\e90f"

.icon-heart:before
  content: "\e910"

.icon-point:before
  content: "\e911"

.icon-setting:before
  content: "\e914"

.icon-lock:before
  content: "\e915"

.icon-bullet:before
  content: "\e908"

.icon-close:before
  content: "\e909"

.icon-down1:before
  content: "\e90a"

.icon-copy:before
  content: "\2750"
