@media (max-width: 767px)
  .form
    border-radius: 0
    width: calc(100% + var(--offset) * 2)
    margin-left: calc(0px - var(--offset))
    &__header
      padding: 3.5rem var(--offset)
    &__content
      padding: 4rem var(--offset)
      grid-gap: 3rem
    &__label
      margin-bottom: 10px
      font-size: 16px
